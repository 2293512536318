<template>
  <div class="bg-white">
    <div class="block block-rounded text-center" id="downloud">
      <div class="block-content">
        <h2>下载中心</h2>
        <p>最新软件，免费下载，定期更新。</p>

        <div class="block block-rounded">
          <p v-for="(itm, inx) in downList" :key="inx">
            <a target="_blank" :href="itm.url"><i v-if="itm.alert" class="si si-info fa-fw mr-1"></i>{{ itm.title }}</a>
          </p>
          <!-- <ul class="nav" data-toggle="tabs" role="tablist">
            <li class="nav-item" v-for="(item, index) in downList" :key="index">
              <a
                :class="current == index ? 'nav-link title-active' : 'nav-link'"
                @click="changeTabs(index)"
                >{{ item.title }}</a
              >
            </li>
          </ul>
          <div class="block-content tab-content">
            <div
              v-for="(item, index) in downList"
              :key="index"
              :class="current == index ? 'tab-pane active' : 'tab-pane'"
              role="tabpanel"
            >
              <p v-for="(itm, inx) in item.list" :key="inx">
                <a target="_blank" :href="itm.url"
                  ><i v-if="itm.alert" class="si si-info fa-fw mr-1"></i
                  >{{ itm.title }}</a
                >
              </p>
            </div>
          </div> -->
        </div>
        <p>continue...</p>
      </div>
    </div>

    <div class="block block-rounded text-center" id="vidio">
      <div class="block-content">
        <h2>产品手册</h2>
        <p>
          最新产品手册，各种产品资料，免费下载，定期更新。。
        </p>
        <p v-for="(item, index) in productList" :key="index + '-note'">
          <a target="_blank" :href="item.url">{{ item.title }}</a>
        </p>
        <p>CONTINUE......</p>
      </div>
    </div>

    <div class="block block-rounded text-center" id="vidio">
      <div class="block-content">
        <h2>用户手册及教程讲义</h2>
        <p>
          自制产品功能视频解说简单教程，定期更新。手把手教程尽在优酷视频，搜索：库软科技。收藏订阅即可第一时间收到产品运用及教程更新资料。
        </p>
        <p v-for="(item, index) in notesList" :key="index + '-note'">
          <a target="_blank" :href="item.url">{{ item.title }}</a>
        </p>
        <p>CONTINUE......</p>
      </div>
    </div>
    <div class="block block-rounded text-center" id="vidio">
      <div class="block-content">
        <h2>旧版软件</h2>
        <p>
          建议下载最新版本软件，旧版软件即将下架，后续不再提供支持。
        </p>
        <p v-for="(item, index) in oldList" :key="index + '-note'">
          <a target="_blank" :href="item.url">{{ item.title }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {
      current: 0,
      downList: [
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/TsSetup_Net0520.zip",
          title: "软件类：开发软件-电脑版本",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/TsSetup_EMB4019.zip",
          title: "软件类：开发软件-嵌入式",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/TsSetup_SFB202405.zip",
          title: "软件类：组态浏览器",
        },
        {
          url: "#",
          title: "安卓APP获取，请直接联系我们",
        }
      ],
      oldList: [
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/TsSetup_Net240301.exe",
          title: "软件类：开发软件-电脑版本(2024版3月)",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/TsSetup_EMB240304.exe",
          title: "软件类：开发软件-嵌入式(2024版3月)",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/SetupFullBrowser_Videop2p240313.exe",
          title: "软件类：组态浏览器(2024版3月)",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/TsSetup_Net240205.rar",
          title: "软件类：开发软件-电脑版本(v7.0.1.5)(2024版)",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/TsSetup_EMB240205.rar",
          title: "软件类：开发软件-嵌入式(v7.0.1.5)(2024版)",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/TsSetup_SFB202307.rar",
          title: "软件类：组态浏览器(v7.0)(2023版)",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/TsSetup_Net202310.rar",
          title: "软件类：开发软件-电脑版本(v7.0.1.5)(old)",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/TsSetup_EMB202310.rar",
          title: "软件类：开发软件-嵌入式(v7.0.1.5)(old)",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/TsSetup_NET_v7.0.1.2_0531.rar",
          title: "软件类：开发软件-电脑版本(v7.0)(old)",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/TsSetup_EMB_v7.0.3_1017.rar",
          title: "软件类：开发软件-嵌入式(v7.0)(old)",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/sdweb_v7.0.1.2_0531.rar",
          title: "软件类：组态浏览器(v7.0)(old)",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/sdvid.zip",
          title: "软件类：视频包(old).zip",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/sdwince.rar",
          title: "软件类：软件开发-Wince(old).rar",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/sdlinux.rar",
          title: "软件类：开发软件-Linux(old).rar",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/sdpc.rar",
          title: "软件类：开发软件-电脑版本(old).rar",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/sdweb.exe",
          title: "软件类：组态浏览器(old)",
        },
      ],
      productList: [
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/scadasoftbook.pdf",
          title: "产品手册类：库软移动SCADA组态软件产品手册",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/dtubook.pdf",
          title: "产品手册类：SuperDTU系列云盒子产品手册",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/cttouchbook.pdf",
          title: "产品手册类：CT系列LINUX云屏产品手册",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/cbl2book.pdf",
          title: "产品手册类：CBL2系列双口云盒子产品手册",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/cbl4book.pdf",
          title: "产品手册类：CBL4系列四口云盒子产品手册",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/cbl1book.pdf",
          title: "产品手册类：CBL1系列四口云盒子产品手册",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/cbl6book.pdf",
          title: "产品手册类：CBL6系列四口云盒子产品手册",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/cblaibook.pdf",
          title: "产品手册类：CBLai系列四口云盒子产品手册",
        },
        {
          url: "https://oss.cnwscada.com/officalWebsite/assets/download/down/gisbook.pdf",
          title: "产品手册类：炫酷电子地图GIS平台解决方案",
        },
      ],
      notesList: [
        {
          url: "https://www.cnwscada.com/help",
          title: "库软移动SCADA组态软件及嵌入式产品用户手册",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDE0MzY1MDE4MA==.html?spm=a2hzp.8244740.0.0",
          title: "20190420.示范运用：酷炫GIS超级链接云屏展示",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDE0MzY1ODM3Mg==.html?spm=a2hzp.8244740.0.0",
          title:
            "20190420.示范运用：魔镜，可视对讲，门禁，实时监控，云屏云盒子完美对接",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEyNDg4NzA2NA==.html?spm=a2hzp.8244740.0.0&f=52144606",
          title: "1.1.示范运用：库软手机组态运用之云屏自动生成APP示范",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEyNTAzNjMzNg==.htmlspm=a2h0j.11185381.listitem_page1.5!2~A&&f=52144606",
          title: "1.2.示范运用：运用云屏云盒子控制市政灯光",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEzNDY4ODY4NA==.html?spm=a2h0j.11185381.listitem_page1.5!3~A&&f=52144606",
          title: "1.3.示范运用：通过库软移动SCADA组态软件配置实现专业UI",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEzNDcwMDA1Mg==.html?spm=a2h0j.11185381.listitem_page1.5!4~A&&f=52144606",
          title: "1.4.示范运用：云盒子加工厂运用",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEzNDcwMjI4OA==.html?spm=a2h0j.11185381.listitem_page1.5!5~A&f=52144606",
          title: "1.5.示范运用：云屏运用在恒温恒湿系统控制中",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEzNDcwNTMxNg==.html?spm=a2hzp.8253869.0.0",
          title: "1.6.示范运用：通过云屏云盒子如何实现控制项目",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEyNDkwODI3Ng==.html?spm=a2hzp.8253869.0.0",
          title: "1.7示范运用：通过云屏云盒子运用在智能家居系统控制中",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEyNTA1NjQ5Mg==.html?spm=a2hzp.8253869.0.0",
          title: "2.1.讲义笔记：软件性能介绍",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEyNTA2Mjk2NA==.html?spm=a2hzp.8253869.0.0",
          title: "2.2.讲义笔记：软件基本介绍",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEyNTA2NzAxMg==.html?spm=a2hzp.8253869.0.0",
          title: "2.3.讲义笔记：权限报警趋势报表",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEyNTA2ODc4MA==.html?spm=a2hzp.8253869.0.0",
          title: "2.4.讲义笔记：微信报警",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEyNTA3MDI2NA==.html?spm=a2hzp.8253869.0.0",
          title: "2.5.讲义笔记：网络科普",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEyNTA3Mjg3Ng==.html?spm=a2hzp.8253869.0.0",
          title: "2.6.讲义笔记：快读开发",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEyNTA3NDI2MA==.html?spm=a2h0j.11185381.listitem_page1.5!15~A",
          title: "2.7.讲义笔记：云屏专题",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEyNTA3NTkyMA==.html?spm=a2hzp.8253869.0.0",
          title: "2.8.讲义笔记：变量和表达式",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEyNTA3ODIyOA==.html?spm=a2h0j.11185381.listitem_page1.5!5~A",
          title: "2.9.讲义笔记：客户端JS脚本",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEyNTEwNTI2MA==.html?spm=a2hzp.8253869.0.0",
          title: "2.10.讲义笔记：数据库软JS",
        },
        {
          url: "https://v.youku.com/v_show/id_XNDEyNTEwNzI5Ng==.html?spm=a2hzp.8253869.0.0",
          title: "2.11.讲义笔记：平台摄像头",
        },
      ],
    };
  },
  methods: {
    changeTabs(index) {
      this.current = index;
    },
  },
};
</script>

<style scoped>
a {
  color: #98c593;
}

h2 {
  font-size: 2.25rem;
}

a:hover {
  cursor: pointer;
}

.nav-main-link-name {
  color: #000000;
}

.title {
  font-size: 28px;
  line-height: 120%;
  padding-bottom: 1.5rem;
  text-align: center;
  padding-top: 2.5rem;
}

.nav {
  justify-content: center;
}

.title-active {
  border-bottom: 1px solid #98c593;
}
</style>
